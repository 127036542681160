export default {
  financial_users: 'Users',
  account_id: 'User ID',
  financial_stats: 'iGaming Stats',
  financial_transactions: 'Transactions',
  forex: 'Analytics Reports',
  broker_commission: 'Brand Commission',
  'Revshare (Financial)': 'Revshare (iGaming)',
  'CPA Qualification (Financial)': 'CPA Qualification (iGaming)',
  min_amount: 'Baseline',

};

import { enableES5 } from 'immer';
import 'nprogress/nprogress.css';
import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-quill/dist/quill.snow.css';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from 'src/App';
import 'src/assets/css/prism.css';
import { SettingsProvider } from 'src/context/SettingsContext';
import 'src/mixins/chartjs';
import 'src/mixins/prismjs';
import * as serviceWorker from 'src/serviceWorker';
import { configureStore } from 'src/store';
import { restoreSettings } from 'src/utils/settings';
// import { IoProvider } from 'socket.io-react-hook';

enableES5();

export const { store, persistor } = configureStore();
const settings = restoreSettings();
// eslint-disable-next-line eqeqeq

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <SettingsProvider settings={settings}>
          <App />
        </SettingsProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();

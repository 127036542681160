import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { companySelector } from 'src/selectors/selectors';
import Label from '../Label';
import { Tooltip } from '@mui/material';

export const MEMBERSHIP_PLANS = {
  FREE: 0,
  SMALL: 1,
  ENTERPRISE: 2
};
export const MEMBERSHIP_PLANS_TO_NAMES = {
  0: 'Free',
  1: 'Small Business',
  2: 'Enterprise'
};

export const MEMBERSHIP_PLANS_TO_COLORS = {
  FREE: 'error',
  SMALL: 'warning',
  ENTERPRISE: 'success'
};

const MembershipGuard = ({
  minPlan,
  allowFree = false,
  useToolTip = false,
  children
}) => {
  const { membership } = useSelector(companySelector);

  // Check if the current membership plan is greater than or equal to the minPlan
  const currentPlanValue = MEMBERSHIP_PLANS[membership?.type] ?? -1;

  // Check if the current membership plan is greater than or equal to the required plan
  const isEnabled =
    currentPlanValue >= minPlan ||
    (allowFree && currentPlanValue == MEMBERSHIP_PLANS.FREE);

  const blockMouseClicksStyle = {
    pointerEvents: isEnabled ? 'auto' : 'none', // Enable or disable pointer events based on membership
    opacity: isEnabled ? 1 : 0.5 // Optionally reduce opacity if disabled
  };

  return useToolTip ? (
    <Tooltip title="Please Upgrade Your Plan">
      <div>
        <div style={blockMouseClicksStyle}>{children}</div>
      </div>
    </Tooltip>
  ) : (
    <>
      <div style={isEnabled ? {} : { position: 'relative' }}>
        {/* If the current plan is less than the required plan, display a label */}
        {!isEnabled && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              zIndex: 500,
              pointerEvents: 'none' // ensure the label doesn't block mouse interactions
            }}
          >
            <Label
              color={'warning'}
            >{`${MEMBERSHIP_PLANS_TO_NAMES[minPlan]}`}</Label>
          </div>
        )}

        {/* Apply styles to block mouse clicks on all children if not enabled */}
        <div style={blockMouseClicksStyle}>{children}</div>
      </div>
    </>
  );
};

MembershipGuard.propTypes = {
  children: PropTypes.any,
  minPlan: PropTypes.any
};

export default MembershipGuard;

export const filterCountryOptions = (
  options,
  state,
  selected,
  selected2 = []
) => {
  const escapedInputValue = state?.inputValue
    ? state.inputValue.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&')
    : '';

  const inputRegex = new RegExp(escapedInputValue, 'i');
  // Filter options to exclude the already selected countries
  return options
    .filter(option => {
      const optionName = option?.name || '';
      return (
        !selected?.some(selected => selected?.name === option?.name) &&
        !selected2?.some(selected => selected?.name === option?.name) &&
        inputRegex.test(optionName)
      );
    })
    .sort((a, b) => {
      // Compare isGroup property (true comes before false)
      if (a.isGroup === b.isGroup) {
        // If isGroup is the same, compare by name in ascending order
        return a.name.localeCompare(b.name);
      }
      // Compare isGroup property (true comes before false)
      return a.isGroup ? -1 : 1;
    });
};

export const groupCountriesByType = option => {
  return option?.isGroup ? 'Groups' : 'Countries';
};

import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import instance from 'src/utils/axios';
import * as yup from 'yup';

const propTypes = {
  children: PropTypes.node.isRequired
};

const DialogOptions = {
  message: PropTypes.string
};

const PromiseInfo = {
  resolve: PropTypes.func.isRequired,
  reject: PropTypes.func.isRequired
};

const ShowDialogHandler = PropTypes.func.isRequired;
/**
 * @typedef {Object} Options
 * @property {any=} options.originalRequest - originalRequest .
 * @property {string=} options.message - Dialog message (optional).
 * @property {boolean=} options.showCancel - Show Cancel (optional).
 * @property {string=} options.customCancelText - customCancelText (optional).
 * @property {string=} options.customApproveText - customApproveText (optional).
 */
/**
 * Function to show a dialog with the specified options.
 *
 * @callback ShowDialogHandler
 * @param {Options} options - Dialog options.
 * @returns {Promise<boolean>} - A promise that resolves with a boolean indicating the user's confirmation.
 */

/**
 * @type {React.Context<ShowDialogHandler>}
 */
const DialogContext = createContext(ShowDialogHandler);

const TwoFactorProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState(null);
  const [promiseInfo, setPromiseInfo] = useState();

  /**
   * Function to show a dialog with the specified options.
   *
   * @param {Object} options - Dialog options.
   * @param {string} options.originalRequest - Original Request.
   * @param {string=} options.message - Dialog message (optional).
   * @returns {Promise<boolean>} - A promise that resolves with a boolean indicating the user's confirmation.
   */
  const showDialog = options => {
    return new Promise((resolve, reject) => {
      setPromiseInfo({ resolve, reject });
      setOptions(options);
      setOpen(true);
    });
  };

  const schema = yup.object().shape({
    token: yup
      .string()
      .length(6, 'Token must be exactly 6 digits')
      .required('Token is required')
  });

  const handleConfirm = async ({ token }) => {
    if (options?.originalRequest) {
      const headers = {
        ...options?.originalRequest.headers,
        '2fa-token': token
      };
      const newRequest = {
        ...options?.originalRequest,
        headers
      }; // Create a new request with the headers and cancel token
      try {
        const reshotResponse = await instance(newRequest);

        setOpen(false); // Close the modal on successful validation
        promiseInfo.resolve(reshotResponse);
      } catch (error) {
        // Handle error

        setOpen(false); // Close the modal on successful validation
        promiseInfo.reject(error); // Reject with the new Error instance
      } finally {
        setPromiseInfo(undefined);
        resetField('token');
      }
    }
  };

  const handleCancel = () => {
    setOpen(false);
    promiseInfo?.resolve('canceled');
    setPromiseInfo(undefined);
  };

  const {
    handleSubmit,
    control,
    resetField,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema)
  });

  return (
    <>
      <Dialog open={open} onClose={handleCancel}>
        <DialogTitle>
          <FormattedMessage id="two_factor_auth_dialog_title" />
        </DialogTitle>
        <form onSubmit={handleSubmit(handleConfirm)}>
          <DialogContent sx={{ minWidth: '400px' }}>
            <DialogContentText sx={{ mb: 3 }}>
              <FormattedMessage id="two_factor_auth_dialog_description" />
            </DialogContentText>
            {/* Using Controller for Material-UI TextField for 6-digit token */}
            <Controller
              name="token"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label={<FormattedMessage id="enter_6_digit_code" />}
                  type="number"
                  InputProps={{ maxLength: 6 }}
                  fullWidth
                  error={!!errors?.token}
                  helperText={errors?.token?.message ?? options?.message}
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            {options?.showCancel && (
              <Button onClick={handleCancel}>
                <FormattedMessage id={options?.customCancelText ?? 'cancel'} />
              </Button>
            )}
            <LoadingButton
              loading={isSubmitting}
              type="submit"
              variant="contained"
            >
              <FormattedMessage id={options?.customApproveText ?? 'submit'} />
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>

      <DialogContext.Provider value={showDialog}>
        {children}
      </DialogContext.Provider>
    </>
  );
};

TwoFactorProvider.propTypes = propTypes;
/**
 * Custom hook to access the showDialog function from the DialogContext.
 *
 * @returns {ShowDialogHandler} - The showDialog function.
 */
export const use2FADialog = () => {
  return useContext(DialogContext);
};

export default TwoFactorProvider;

export default {
  sign_up: 'Registrarse',
  create_x_account: 'Crear cuenta de {name}.',
  have_an_account: '¿Tienes una cuenta?',
  back_to_home: 'Volver a inicio',
  terms: 'términos',
  back: 'Atrás',
  disagree: 'no estoy de acuerdo',
  i_agree: 'estoy de acuerdo',
  i_have_read_the: 'He leído los',
  terms_and_conditions: 'Términos y Condiciones',
  create_account: 'Crear Cuenta',
  general_error: 'Error General',
  sign_in: 'Iniciar sesión',
  sign_in_on_the_internal_platform:
    'Iniciar sesión en la plataforma de gestión de afiliados',
  create_new_account: 'Crear Nueva Cuenta',
  forgot_password: 'Olvidé la contraseña',
  advertisers: 'Anunciantes',
  advertiser: 'Anunciante',
  home: 'Inicio',
  dashboard: 'Panel de control',
  management: 'Gestión',
  export: 'Exportar',
  import: 'Importar',
  all_advertisers: 'Todos los Anunciantes',
  new_advertiser: 'Nuevo Anunciante',
  add_advertiser: 'Agregar Anunciante',
  settings_updated_successfully: 'Configuración Actualizada Exitosamente',
  save_changes: 'Guardar Cambios',
  save: 'Guardar',
  change_email: 'Cambiar correo electrónico',
  email_address: 'Dirección de Email',
  confirm_email_address: 'Confirmar Dirección de Email',
  last_name: 'Apellido',
  first_name: 'Nombre',
  profile: 'Perfil',
  change_password: 'Cambiar Contraseña',
  password_confirmation: 'Confirmación de Contraseña',
  password: 'Contraseña',
  min_pass_length_message:
    'Has ingresado una contraseña inválida. Por favor utiliza de 7 a 20 caracteres.',
  password_required: 'Contraseña requerida',
  enter_valid_email: 'Debe ser un correo electrónico válido',
  email_required: 'Email requerido',
  invalid_password_message:
    'Contraseña inválida, por favor ingrese una contraseña válida',
  invalid_format: 'Formato inválido, por favor siga el ejemplo',
  invalid_email_message: 'Correo Electrónico Inválido',
  log_in: 'Iniciar Sesión',
  first_name_validation:
    'El nombre solo puede contener letras (a-z), números (0-9) y puntos (.) [con caracteres después] y tener entre 2 y 20 caracteres',
  last_name_validation:
    'El apellido solo puede contener letras (a-z), números (0-9) y puntos (.) [con caracteres después] y hasta 20 caracteres de longitud',
  submit: 'enviar',
  emails_must_match: 'Ambos correos electrónicos deben ser iguales',
  campaign: 'Campaña',
  campaigns: 'Campañas',
  all_campaigns: 'Todas las Campañas',
  new_campaign: 'Nueva Campaña',
  add_campaign: 'Agregar Campaña',
  all: 'Todo',
  title: 'Título',
  description: 'Descripción',
  status: 'Estado',
  fileStatus: 'Estado del Archivo',
  privacy: 'Privacidad',
  privacy_policy: 'Política de Privacidad',
  active: 'Activo',
  disable: 'Deshabilitar',
  disabled: 'Deshabilitado',
  paused: 'Pausado',
  public: 'Público',
  pre_moderation: 'Pre Moderación',
  private: 'Privado',
  no_results_found: 'No se encontraron resultados',
  this_field_is_required: 'este campo es requerido',
  actions: 'Acciones',
  general: 'General',
  tracking: 'Rastreo',
  traffic_source: 'Fuente de Tráfico',
  traffic_sources: 'Fuentes de Tráfico',
  currency: 'Moneda',
  features: 'Características',
  featured: 'Destacado',
  start_date: 'Fecha de Inicio',
  end_date: 'Fecha de Fin',
  hold_time: 'Tiempo de Espera',
  successfully_updated: 'Actualizado Exitosamente',
  successfully_created: 'Creado Exitosamente',
  last_update_newest_first: 'Última actualización (el más nuevo primero)',
  last_update: 'Última Actualización',
  last_update_oldest_first: 'Última actualización (el más antiguo primero)',
  total_orders_high_to_low: 'Total de pedidos (de mayor a menor)',
  total_orders_low_to_high: 'Total de pedidos (de menor a mayor)',
  delete: 'Eliminar',
  edit: 'Editar',
  name: 'Nombre',
  website: 'Sitio Web',
  contact_person: 'Persona de Contacto',
  im_or_skype: 'Im / Skype',
  full_name: 'Nombre Completo',
  address1: 'Dirección1',
  address2: 'Dirección2',
  allowed_ips: 'IPs Permitidas',
  note: 'Nota',
  secure_postback_code: 'Código de Postback Seguro',
  vat_code: 'Código de IVA',
  zipcode: 'Código Postal',
  true: 'Verdadero',
  false: 'Falso',
  update_advertiser: 'Actualizar Anunciante',
  create_advertiser: 'Crear Anunciante',
  edit_advertiser: 'Editar Anunciante',
  update_campaign: 'Actualizar Campaña',
  create_campaign: 'Crear Campaña',
  settings: 'Configuraciones',
  create_traffic_source: 'Crear Fuente de Tráfico',
  update_traffic_source: 'Actualizar Fuente de Tráfico',
  edit_traffic_source: 'Editar Fuente de Tráfico',
  add_traffic_source: 'Agregar Fuente de Tráfico',
  create: 'Crear',
  update: 'Actualizar',
  traffic_source_created: 'Fuente de Tráfico Creada',
  new_company_traffic_source: 'Nueva Fuente de Tráfico de la Empresa',
  new_traffic_source: 'Nueva Fuente de Tráfico',
  edit_campaign: 'Editar Campaña',
  tracking_url: 'URL de Rastreo',
  preview_url: 'URL de Vista Previa',
  redirect_type: 'Tipo de Redirección',
  traffic_back_url: 'URL de Retorno de Tráfico',
  no_advertisers_found: 'No se encontraron anunciantes',
  no_campaign_create_advertiser:
    'Para crear una campaña, por favor cree un anunciante primero',
  create_traffic_source_first:
    'Para crear una campaña primero necesitas una fuente de tráfico',
  discounted_prices: 'Precios con Descuento',
  email_verified: 'Correo Electrónico Verificado',
  unique_ip_only: 'Se Requiere IP Única',
  one_of_ips_invalid:
    'Formato de Dirección IP Inválido: Por favor ingrese una dirección IP válida en el formato correcto (por ejemplo, 192.168.1.1). Asegúrese de que cada sección sea un número entre 0 y 255 y esté separado por puntos.',
  s2s_postback: 'S2S (Postback)',
  probabilistic_attribution: 'Atribución Probabilística',
  image: 'Imagen',
  js: 'JavaScript',
  iframe: 'Iframe',
  next: 'Siguiente',
  ip_is_invalid: 'La IP es inválida.',
  payout: 'Pago',
  payouts: 'Pagos',
  revenue: 'Ingresos',
  goal_value: 'Valor del Objetivo',
  goal_title: 'Título del Objetivo',
  payment_type: 'Tipo de Pago',
  countries: 'Países',
  invalid: 'Inválido',
  general_settings_title: 'Título de Configuración General',
  general_settings_start_date: 'Fecha de Inicio de Configuración General',
  general_settings_end_date: 'Fecha de Fin de Configuración General',
  general_settings_hold_time: 'Tiempo de Espera de Configuración General',
  tracking_settings_integration_s2s_postback:
    'Configuración de Rastreo Integración S2s Postback',
  tracking_settings_traffic_back_url:
    'Configuración de Rastreo URL de Retorno de Tráfico',
  tracking_settings_preview_url: 'Configuración de Rastreo URL de Vista Previa',
  tracking_settings_tracking_url: 'Configuración de Rastreo URL de Rastreo',
  payouts_settings_goal_title: 'Configuración de Pagos Título del Objetivo',
  payouts_settings_goal_value: 'Configuración de Pagos Valor del Objetivo',
  payouts_settings_revenue: 'Configuración de Pagos Ingresos',
  payouts_settings_payouts: 'Configuración de Pagos',
  field: 'Campo',
  ip_should_be_unique: 'La IP Debe Ser Única',
  required: 'Requerido',
  field_invalid: 'Campo Inválido',
  general_payout: 'Pago General',
  personal_payout: 'Pago Personal',
  valid: 'Válido',
  resource_name_unavailable: 'Nombre de recurso no disponible',
  cancel: 'Cancelar',
  end_date_is_invalid: 'La fecha de fin es inválida',
  affiliates: 'Afiliados',
  affiliate: 'Afiliado',
  click_session_life_span: 'Duración de la Sesión de Clic',
  security: 'Seguridad',
  password_confirm: 'Confirmar Contraseña',
  current_password: 'Contraseña Actual',
  name_unavailable: 'Nombre No Disponible',
  statistics: 'Estadísticas',
  payouts_soon: 'Pagos (Próximamente)',
  conversions_soon: 'Conversiones (Próximamente)',
  affiliates_soon: 'Afiliados (Próximamente)',
  campaign_stats: 'Informes',
  campaign_id: 'ID de Campaña',
  campaign_name: 'Nombre de Campaña',
  hosts: 'Hosts',
  hits: 'Hits',
  approved: 'Aprobado',
  approve_affiliate: 'Aprobar Afiliado',
  reject_affiliate: 'Rechazar Afiliado',
  disapproved: 'Desaprobado',
  pending: 'Pendiente',
  declined: 'Rechazado',
  decline: 'Rechazar',
  cr: 'CR',
  epc: 'EPC',
  traffic: 'Tráfico',
  conversions: 'Conversiones',
  finances: 'Finanzas',
  filters: 'Filtros',
  hold: 'Retención',
  total: 'Total',
  qty: 'Cantidad',
  null: 'Nulo',
  trafficback: 'Retorno de Tráfico',
  earnings: 'Ganancias Totales',
  ecpm: 'ECPM',
  clear_filters: 'Limpiar Filtros',
  not_selected: 'No Seleccionado',
  filter: 'Filtrar',
  conversion_id: 'ID de Conversión',
  comment: 'Comentario',
  user_agent: 'Agente de Usuario',
  sub_id: 'ID de Sub',
  sub_id_text: 'Texto de ID de Sub',
  sub_1: 'sub1',
  sub_2: 'sub2',
  sub_3: 'sub3',
  sub_4: 'sub4',
  sub_5: 'sub5',
  sub_6: 'sub6',
  sub_7: 'sub7',
  sub_8: 'sub8',
  sub1: 'Sub 1',
  sub2: 'Sub 2',
  sub3: 'Sub 3',
  sub4: 'Sub 4',
  sub5: 'Sub 5',
  sub6: 'Sub 6',
  sub7: 'Sub 7',
  sub8: 'Sub 8',
  goal_name: 'Nombre del Objetivo',
  time_period: 'Periodo de Tiempo',
  income: 'Ingresos',
  device: 'Dispositivo',
  geography_ip: 'Geografía / IP',
  amount: 'Monto',
  date: 'Fecha',
  today: 'HOY',
  yesterday: 'AYER',
  last_week: 'ÚLTIMA SEMANA',
  last_two_weeks: 'ÚLTIMAS 2 SEMANAS',
  last_month: 'ÚLTIMO MES',
  last_three_months: 'ÚLTIMOS 3 MESES',
  last_six_months: 'ÚLTIMOS 6 MESES',
  last_year: 'ÚLTIMO AÑO',
  custom: 'PERSONALIZADO',
  conversion_stats: 'Estadísticas de Conversión',
  create_conversion: 'Crear Conversión',
  update_conversion: 'Actualizar Conversión',
  edit_conversion: 'Editar Conversión',
  all_conversions: 'Todas las Conversiones',
  add_conversion: 'Agregar Conversión',
  conversion_created: 'Conversión Creada',
  conversion_updated: 'Conversión Actualizada',
  currently_unavailable: 'Actualmente No Disponible',
  creation_date: 'Fecha de Creación',
  all_payouts: 'Todos los Pagos',
  add_payout: 'Agregar Pago',
  add: 'Agregar',
  create_payout: 'Crear Pago',
  denied: 'Denegado',
  number: 'Número',
  created_at: 'Creado En',
  approved_and_paid: 'Aprobado y Pagado',
  order_id: 'ID de Orden',
  include_affiliate: 'Incluir Afiliado',
  amount_to_be_approved: 'Monto a Aprobar',
  commission_to_be_approved: 'Comisión a Aprobar',
  edit_payout: 'Editar Pago',
  advertiser_name: 'Nombre del Anunciante',
  total_amount: 'Monto Total',
  campaign_start_date: 'Fecha de Inicio de la Campaña',
  campaign_end_date: 'Fecha de Finalización de la Campaña',
  update_payout: 'Actualizar Pago',
  total_commission: 'Comisión Total',
  download_report: 'Descargar Informe',
  file_uploaded_successfully: 'archivo subido con éxito',
  download_latest_report: 'Descargar Informe Subido',
  download_original_report: 'Descargar Informe Original',
  payout_updated: 'Pago Actualizado',
  include_commission: 'Incluir Comisión',
  no_report_attached: 'sin informe adjunto',
  report_is_valid: 'El Informe es Válido',
  view_payout: 'Ver Pago',
  affiliates_requests: 'Solicitudes de Afiliados',
  requests: 'Solicitudes',
  approve: 'Aprobar',
  affiliate_payouts: 'Pagos de Afiliados',
  save_and_next: 'Guardar y Siguiente',
  available_to_payout: 'Disponible para Pago',
  request_payout: 'Solicitar Pago',
  commission: 'Comisión',
  affiliate_commission: 'Comisión de Afiliado',
  see_all: 'Ver Todo',
  clicks: 'Clics',
  more_details: 'Más Detalles',
  email: 'Correo Electrónico',
  affiliate_website: 'Sitio Web de Afiliado',
  disapprove: 'Desaprobar',
  change_to_approved: 'Cambiar a Aprobado',
  change_to_declined: 'Cambiar a Rechazado',
  contact_details: 'Detalles de Contacto',
  login_as_affiliate: 'Iniciar Sesión como Afiliado',
  login: 'Iniciar Sesión',
  add_affiliate: 'Agregar Afiliado',
  create_affiliate: 'Crear Afiliado',
  edit_affiliate: 'Editar Afiliado',
  try_again_later: 'Intentar de Nuevo Más Tarde',
  added_affiliate: 'Afiliado Agregado',
  affiliate_management: 'Gestión de Afiliados',
  merchant_commission: 'Comisión',
  phone_number: 'Número de Teléfono',
  affiliate_updated: 'Afiliado Actualizado',
  affiliate_created: 'Afiliado Creado',
  merchant_management: 'Gestión',
  user_management: 'Gestión de Usuarios',
  identity: 'identidad',
  smtp: 'SMTP',
  edit_merchant: 'Editar Gestión de Usuarios',
  merchant_updated: 'Gestión de Usuarios Actualizada',
  add_merchant: 'Agregar Gestión de Usuarios',
  create_merchant: 'Crear Gestión de Usuarios',
  company_name: 'Afiliado',
  company_name_for_invoice: 'Nombre de la Empresa',
  update_merchant: 'Actualizar Comerciante',
  campaign_logo: 'Logotipo de la Campaña',
  io_document: 'Documento IO',
  select_logo: 'Seleccionar Logotipo',
  select_io_document: 'Seleccionar Documento IO',
  img: 'Imagen',
  picture_unavailable: 'Imagen No Disponible',
  logo: 'Logotipo',
  id: 'ID',
  select_campaign: 'Seleccionar Campaña',
  js_landing_page: 'Página de Aterrizaje Javascript',
  js_thank_you: 'Js Gracias',
  js_landing_page_text:
    'Un anunciante debe colocar este código después de la etiqueta <body> en la página de aterrizaje. Es necesario agregar el parámetro &click_id={click_id} en la URL de seguimiento',
  js_thank_you_text:
    'Un anunciante debe colocar este código después de la etiqueta <body> en la página de éxito "Gracias"',
  end_date_before_start_date:
    'La fecha de finalización es anterior a la fecha de inicio',
  login_email: 'Correo Electrónico de Inicio de Sesión',
  login_password: 'Contraseña de Inicio de Sesión',
  server: 'Servidor',
  port: 'Puerto',
  protocol: 'Protocolo',
  smtp_and_ssl: 'SMTP + SSL',
  smtp_and_tls: 'SMTP + TLS',
  created: 'Creado',
  updated: 'Actualizado',
  forgot_password_message:
    'Ingrese su dirección de correo electrónico a continuación para restablecer su contraseña.',
  search_affiliate: 'Buscar Afiliado',
  search_advertisers: 'Buscar Anunciantes',
  search_campaigns: 'Buscar campañas',
  search_payouts: 'Buscar Pagos',
  search_conversions: 'Buscar Conversiones',
  search_campaign_request: 'Buscar Solicitudes de Campaña',
  custom_domain: 'Dominio Personalizado SSL',
  branding: 'Marca',
  request_details: 'Detalles de la Solicitud',
  search_traffic_source: 'Buscar Fuente de Tráfico',
  performance_chart: 'Rendimiento',
  last_conversions: 'Últimas Conversiones',
  last_30_days: 'ÚLTIMOS 30 DÍAS',
  reports: 'Informes',
  documents: 'Documentos',
  search: 'Buscar',
  SUB1: 'SUB1',
  SUB2: 'SUB2',
  SUB3: 'SUB3',
  SUB4: 'SUB4',
  SUB5: 'SUB5',
  SUB6: 'SUB6',
  SUB7: 'SUB7',
  SUB8: 'SUB8',
  top_campaigns: 'Principales Campañas',
  Paused: 'Pausado',
  Disabled: 'Deshabilitado',
  logout: 'Cerrar Sesión',
  Active: 'Activo',
  click_session_days: 'Días de la Sesión de Clic',
  click_session_hours: 'Horas de la Sesión de Clic',
  statuses: 'Estados',
  enter_new_password: 'Introducir nueva contraseña',
  download: 'Descargar',
  not_active: 'No Activo',
  activate: 'Activar',
  test_email: 'Enviar Correo Electrónico de Prueba',
  alias: 'Alias',
  smtp_connection_failed_change_details:
    'La conexión SMTP ha fallado, considere cambiar los ajustes.',
  send_test_mail: 'Enviar Correo Electrónico de Prueba',
  smtp_connected_successfully: 'SMTP conectado con éxito',
  mail: 'Correo',
  header: 'Encabezado',
  footer: 'Pie de página',
  attribution: 'Atribución',
  first_click: 'Primer Clic',
  last_click: 'Último Clic',
  earn_per_click: 'Ganar Por Clic',
  click_rate: 'Tasa de Clics',
  campaign_details: 'Detalles de la Campaña',
  disallowed: 'No permitido',
  preview: 'Vista previa',
  hint: 'Pista',
  coupon: 'Cupón',
  cname_missing_add_to_dns_manager:
    'Falta el CNAME, añadir CNAME a su administrador de DNS.',
  new_affiliate_needs_activation:
    'Verificación de Correo Electrónico de Nuevo Afiliado',
  new_affiliate_needs_manual_activation:
    'Nuevo Afiliado Necesita Activación Manual',
  change_manager: 'Cambiar Gerente',
  manager: 'Gerente',
  default_merchant: 'Comerciante por Defecto',
  merchant: 'Comerciante',
  need_help: 'Necesita ayuda',
  check_our_docs: 'Consulte nuestros documentos',
  documentation: 'Documentación',
  activation: 'Activación',
  changed_email: 'Correo Cambiado',
  reset_password: 'Restablecer Contraseña',
  invitation: 'Invitación',
  password_changed: 'La contraseña ha sido cambiada',
  enter_email: 'Introducir Correo Electrónico',
  and: 'y',
  welcome: 'Correo Electrónico de Activación',
  available_macros: 'Macros Disponibles',
  custom_fields: 'Campos Personalizados',
  add_custom_field: 'Añadir Campo Personalizado',
  search_custom_field: 'Buscar Campo Personalizado',
  edit_custom_field: 'Editar Campo Personalizado',
  create_custom_field: 'Crear Campo Personalizado',
  update_custom_field: 'Actualizar Campo Personalizado',
  add_custom_doc: 'Añadir Documento Personalizado',
  search_custom_doc: 'Buscar Documento Personalizado',
  edit_custom_doc: 'Editar Documento Personalizado',
  create_custom_doc: 'Crear Documento Personalizado',
  update_custom_doc: 'Actualizar Documento Personalizado',
  custom_field_name: 'Nombre del Campo Personalizado',
  not_required: 'No Requerido',
  field_name_is_already_used: 'El nombre del campo ya está en uso',
  are_you_sure: '¿Está seguro?',
  are_you_sure_you_want_to_delete: '¿Está seguro de que quiere eliminar?',
  text_area: 'Área de Texto',
  check_box: 'Casilla de Verificación',
  input: 'Entrada',
  type: 'Tipo',
  field_type: 'Tipo de Campo',
  default_manager: 'Gerente por Defecto',
  brand_logo: 'Logotipo de Marca',
  reached_max_campaigns_limit: 'Se ha alcanzado el límite máximo de campañas',
  billing: 'Facturación',
  payments: 'Pagos',
  plan: 'Plan',
  paypal: 'Paypal',
  wire_transfer: 'Transferencia Bancaria',
  payout_source: 'Fuente de Pago',
  price: 'Precio',
  transaction_id: 'ID de Transacción',
  view_invoice: 'Ver Factura',
  upgrade: 'Mejorar',
  work_email: 'Correo Electrónico de Trabajo',
  message: 'Mensaje',
  contact_us: 'Contáctenos',
  we_will_contact_u_soon: '¡Nos pondremos en contacto con usted pronto!',
  terms_and_conditions_and_privacy_policy:
    'Términos y Condiciones, y Política de Privacidad',
  current_email: 'Correo Electrónico Actual',
  please_update_your_plan: 'por favor actualice su plan',
  here: 'aquí',
  custom_domain_updated: 'Dominio personalizado actualizado',
  your_email: 'Tu Correo Electrónico',
  allowed: 'Permitido',
  created_merchant: 'Comerciante Creado',
  affiliate_company: 'Empresa Afiliada',
  linked_to_payout: 'Vinculado al pago',
  subject: 'Asunto',
  has_payout: 'Tiene Pago',
  yes: 'Sí',
  no: 'No',
  select: 'Seleccionar',
  view_conversions: 'Ver Conversiones',
  search_conversions_by_campaign_affiliate_or_payout:
    'Buscar por nombre de campaña o nombre de empresa afiliada.',
  traffic_back_explanation:
    'El cliente será redirigido a "URL de Retorno de Tráfico" en caso de fallo o si la campaña está desactivada.',
  goal_value_explanation_row_1:
    "El 'Valor del Objetivo' ofrece la opción de pagar por eventos o categorías.",
  goal_value_explanation_row_2:
    "El 'Valor del Objetivo' debe ser devuelto por su código/postback.",
  test_smtp_email: 'Probar Correo Electrónico SMTP',
  campaign_terms: 'Términos de la Campaña',
  new_email: 'Nuevo Correo Electrónico',
  payment_details: 'Detalles de Pago',
  request_date: 'Fecha de Solicitud',
  reason: 'Razón',
  affiliate_request: 'Solicitud de Afiliado',
  the_reason_will_be_visible_to_the_affiliate:
    'La razón será visible para el afiliado',
  affiliate_registration_url: 'URL de Registro de Afiliado',
  copy: 'Copiar',
  copied_to_clipboard: 'Copiado al portapapeles',
  minimum_withdrawal_amount: 'Cantidad Mínima de Retiro',
  payment_methods: 'Métodos de Pago',
  add_payment_method: 'Añadir Método de Pago',
  search_payment_method: 'Buscar Método de Pago',
  create_payment_method: 'Crear Método de Pago',
  edit_payment_method: 'Editar Método de Pago',
  payment_method_created: 'Método de Pago Creado',
  payment_method: 'Método de Pago',
  change_email_confirmation_email_sent:
    'Se ha enviado un correo electrónico de confirmación a su correo electrónico actual',
  check_your_data: 'Error',
  are_you_sure_you_want_to_create_a_payout:
    '¿Está seguro de que quiere crear un pago?',
  create_payout_confirmation: 'Confirmación de creación de pago',
  payout_statuses_explanation:
    '* Estados de Pago: Aprobado- pago disponible para el pago. Pendiente - el proceso de pago aún necesita ser completado. Denegado - pago ha sido rechazado por una razón particular.',
  payout_created: 'Pago Creado',
  system_id: 'ID del Sistema',
  tax: 'Impuesto',
  number_must_be_between: 'El número debe estar entre {min} y {max}',
  ready: 'Listo',
  edit_statuses: 'Editar Estados',
  apply_changes_to: 'Aplicar Cambios A ({count})',
  failed: 'Fallido',
  processing: 'Procesando',
  affiliate_payout: 'Pago de Afiliado',
  permissions: 'Permisos',
  enter_new_password_reset:
    'Introduzca su nueva contraseña a continuación para restablecer su contraseña.',
  referer: 'Referente',
  coupon_code: 'Código de Cupón',
  country: 'País',
  please_enable_smtp_first: 'Por favor, habilite SMTP primero',
  scale: 'Escala',
  rotate: 'Rotar',
  crop: 'Recortar',
  time_format: 'Formato de Hora',
  time_zone: 'Zona Horaria',
  billing_description:
    'Descubre la página de facturación de la plataforma de afiliados Tracknow. Empieza tu viaje en el marketing de afiliados gratis con nuestro plan Gratuito. Mejora a nuestros planes Equipo o Empresa a medida que creces, desbloqueando funciones avanzadas. Opciones de pago flexibles disponibles. Únete hoy y desata el poder de Tracknow!',
  personal_settings: 'Configuración Personal',
  company_settings: 'Configuración de la Empresa',
  day: 'Día',
  year: 'Año',
  month: 'Mes',
  group_by: 'Agrupar por',
  deep_link: 'Enlace Profundo',
  tracking_params: 'Parámetros de Seguimiento',
  order_amount: 'Cantidad del Pedido',
  unlimited_sales: 'Ventas Ilimitadas',
  up_to_x_affiliates: 'Hasta {0} Afiliados',
  ssl_setup: 'Configuración SSL',
  dedicated_smtp: 'SMTP Dedicado',
  custom_commissions: 'Comisiones Personalizadas',
  custom_commission_affiliate: 'Comisiones Personalizadas para Afiliados',
  email_support: 'Soporte por Correo Electrónico',
  no_limit_campaigns: 'Campañas Ilimitadas',
  no_limit_affiliates: 'Afiliados Ilimitados',
  email_and_chat_support: 'Soporte por Correo Electrónico y Chat',
  dedicated_account_manager: 'Gerente de Cuenta Dedicado',
  coupon_offline: 'Seguimiento de Cupones',
  invoices: 'Facturas',
  next_subscription_fee_due: 'Próximo pago de suscripción:',
  you_wiil_return_to_the_free_tier_at:
    'Tu suscripción fue cancelada. Por favor, contacta a nuestro soporte en support@tracknow.io',
  are_you_sure_you_want_to_reset_cname:
    '¿Estás seguro de que quieres restablecer el dominio personalizado?',
  reset_cname: 'Restablecer dominio personalizado',
  subscription_details: 'Detalles de la suscripción:',
  max_x_capmaigns: '{0} Dominios/Ofertas',
  unlimited_campaigns: 'Dominios/Ofertas Ilimitados',
  tax_not_included: 'Impuestos no incluidos',
  email_for_invoice: 'Correo electrónico para factura:',
  plans_and_pricing: 'Planes y Precios',
  reset: 'Restablecer',
  your_membership_tier: 'Tu nivel de membresía:',
  click_here: 'Haz clic aquí',
  advertiser_should_add_the:
    'El anunciante debe agregar los siguientes parámetros a la URL: order_id, amount, goal, para más opciones de seguimiento, por favor',
  for_a_tutorial_on_how_to_add_postback:
    'Para un tutorial sobre cómo añadir postback, por favor',
  payout_settings: 'Configuración de Pagos',
  brand_colors: 'Colores de la Marca',
  custom_script: 'Script Personalizado',
  getting_started: 'Empezando',
  done: 'Hecho',
  deleted_successfully: 'Eliminado con éxito',
  creatives: 'Creativos',
  add_new: 'Agregar Nuevo',
  create_a_campaign: 'Crear una Campaña',
  adding_an_affiliate: 'Agregando un Afiliado',
  affiliate_dashboard: 'Panel de Afiliados',
  feel_free_to_contact_us: 'Para más asistencia, por favor contáctanos',
  campaign_management: 'Gestión de Campañas',
  add_new_crative: 'Agregar Nuevo Creativo',
  are_you_sure_you_want_to_delete_this_creative: 'Eliminar',
  delete_notify: '¿Estás seguro de que quieres eliminar este creativo?',
  creative: 'Creativo',
  mlm_commission: 'Comisión de Referidos',
  mlm_level2_commission: 'Comisión de Segundo Nivel de Referidos',
  allow_mlm: 'Permitir MLM (Marketing Multinivel)',
  mlm: 'Marketing Multinivel (MLM)',
  mlm_percent: 'Porcentaje MLM',
  allow_mlm_level2: 'Permitir Nivel 2 MLM',
  mlm_level2_percent: 'Porcentaje de Nivel 2 MLM',
  referrer: 'Referidor',
  default_campaign: 'Campaña Predeterminada',
  conversion_tracking_setup: 'Configuración de Seguimiento de Conversiones',
  content: 'Contenido',
  inactive: 'Inactivo',
  draft: 'Borrador',
  none: 'Ninguno',
  invalid_url: 'URL Inválida',
  categories_tracking: 'Seguimiento de Categorías',
  phone_support: 'Soporte Telefónico',
  chat_support: 'soporte por chat',
  unlimited_banners: 'Creativos Ilimitados',
  impressions: 'Impresiones (CPM)',
  cpm_commissions: 'Comisiones CPM',
  conversion_commissions: 'Comisiones por Conversión',
  ppc_commissions: 'Comisiones PPC',
  support_app_conversions: 'Soporte para conversiones de APP (Android/IOS)',
  support_app_helper:
    '* En caso de que el clic y el píxel de conversión provengan de diferentes navegadores.',
  requested_commission: 'Comisión Solicitada',
  manage: 'Gestionar',
  select_a_payout: 'Seleccionar un Pago',
  select_a_campaign: 'Seleccionar una Campaña',
  use_general_capmaign_settings: 'Usar Configuración General de Campaña',
  add_user_to_campaign: 'Asignar Afiliado a Campaña',
  payout_groups: 'Grupos de Pago',
  commissions: 'Comisiones',
  affiliate_details: 'Detalles del Afiliado',
  creative_empty_desc: 'Sin Creativos.',
  country_code_required: 'Se requiere país en el registro',
  custom_docs: 'Documentos Personalizados',
  APPROVED: 'Aprobado',
  REJECTED: 'Rechazado',
  PENDING: 'Pendiente',
  NEEDS_ATTENTION: 'Necesita Atención',
  INCOMPLETE: 'Incompleto',
  EXPIRED: 'Expirado',
  update_status: 'Actualizar Estado',
  options: 'Opciones',
  select_helper:
    'Separa los valores de selección con una coma. Ejemplo: Hombre,Mujer.',
  select_files: 'Seleccionar archivos',
  drop_files_here_or_click_browse_through_your_machine:
    'Suelta archivos aquí o haz clic para explorar en tu máquina',
  remove_all: 'Eliminar todo',
  upload_files: 'Subir archivos',
  no_documents_required: 'No se requieren documentos',
  admin_status_changed_email: 'Estado de administrador cambiado',
  admin_status_approved_email: 'Estado de administrador aprobado',
  when_company_requires_documents_upload_here:
    'Cuando una empresa requiere documentos, necesitarás subirlos aquí',
  single_campaign_mode: 'Modo de Campaña Única',
  single_campaign_mode_off: 'Todas las campañas son accesibles.',
  single_campaign_mode_on:
    'El acceso está restringido a campañas predeterminadas (empresa o usuario).',
  country_grouping: 'Agrupación por País',
  grouping: 'Agrupación',
  create_grouping: 'Crear Grupo',
  symbols: 'Símbolos',
  symbol_grouping: 'Agrupación de Símbolos',
  create_symbol: 'Crear Símbolo',
  default_status: 'Estado Predeterminado',
  payout_type: 'Tipo de Pago',
  confirmation: 'Confirmación',
  copy_short_url: 'Copiar Enlace Corto',
  generate_short_url: 'Generar Enlace Corto',
  short_url: 'Enlace Corto',
  after_activation:
    'Bienvenido (El correo electrónico se enviará solo cuando exista texto en el cuerpo)',
  users: 'Usuarios',
  forex: 'Finanzas',
  add_user: 'Agregar Usuario',
  edit_user: 'Editar Usuario',
  first_deposit_date: 'Fecha del Primer Depósito',
  user_id: 'ID del Usuario',
  lead_id: 'ID del Lead',
  balance: 'Balance',
  volume: 'Volumen',
  lastAffiliateGroupChangeDate: 'Última Fecha de Cambio de Grupo de Afiliados',
  firstDepositDate: 'Fecha del Primer Depósito',
  account_id: 'Id de la Cuenta',
  account_currency: 'Moneda de la Cuenta',
  add_account: 'Agregar Cuenta',
  edit_account: 'Editar Cuenta',
  add_transaction: 'Agregar Transacción',
  edit_transaction: 'Editar Transacción',
  accounts: 'Cuentas',
  account: 'Cuenta',
  forex_account: 'Cuenta de Forex',
  profit: 'Beneficio',
  symbol: 'Símbolo',
  transactions: 'Transacciones',
  transaction_date: 'Fecha de la Transacción',
  open_price: 'Precio de Apertura',
  close_price: 'Precio de Cierre',
  show_symbols: 'Mostrar Símbolos',
  qualified: 'Calificado',
  leverage: 'Apalancamiento',
  user: 'Usuario',
  symbol_payout_modal_title: 'Agregar Precio para Símbolo',
  symbol_payout_modal_desc: 'Agregar Precio para Símbolo',
  position_count: 'Cantidad de Posiciones',
  net_deposit: 'Depósito Neto',
  ask_price: 'Precio de Venta',
  bid_price: 'Precio de Compra',
  transaction_type: 'Tipo de Transacción',
  broker_commission: 'Comisión del Corredor',
  revshare_type: 'Tipo de Participación en Ingresos',
  currency_price: 'Precio de la Moneda',
  converted_volume: 'Volumen Convertido',
  more_options: 'Más Opciones',
  financial_users: 'Usuarios Financieros',
  financial_accounts: 'Cuentas Financieras',
  first_time_deposit: 'Primer Depósito',
  new_country_grouping: 'Nuevo Grupo de Países',
  new_symbol_grouping: 'Nuevo Grupo de Símbolos',
  currency_change_title: 'Alerta: Confirmación de Cambio de Moneda',
  currency_change_desc:
    'Estás a punto de cambiar la moneda de tu empresa. Ten en cuenta que esto cambiará el valor de tu moneda de conversión y puede afectar la configuración de tu cuenta.',
  tax_id: 'Identificación Fiscal',
  affiliate_settings: 'Configuraciones de Afiliados',
  hideOrderId: 'Ocultar ID de Orden (Afiliado)',
  hideAmount: 'Ocultar Monto (Afiliado)',
  allow_ip_explain:
    'Solo se permitirán postbacks de estas IPs (IPs en la lista blanca). Las IPs deben estar separadas por una coma. Por ejemplo: 127.0.0.1, 192.17.17.1. Mantén la entrada vacía para permitir todas las direcciones IP.',
  setup_included: 'Configuración Incluida',
  affiliate_platform_name: 'Nombre de la Plataforma de Afiliados',
  become_an_advertiser: 'Conviértete en Anunciante',
  become_an_advertiser_desc:
    'Por favor, complete el formulario a continuación para solicitar unirse a nuestra plataforma como anunciante',
  become_an_advertiser_res:
    'Gracias por enviar su consulta para convertirse en anunciante. Una vez aprobado, recibirá las credenciales de inicio de sesión por correo electrónico',
  advertiser_registrations: 'Registros de Anunciantes',
  allow_affiliate_register: 'Permitir Registro de Afiliados',
  allow_advertiser_register: 'Permitir Registro de Anunciantes',
  advertiser_user_management: 'Gestión de Usuarios Anunciantes',
  advertiser_login: 'Inicio de Sesión de Anunciantes',
  advertiser_user_login_desc:
    'Tenga en cuenta que cada acción realizada tras el inicio de sesión es de su exclusiva responsabilidad y afectará a la cuenta de anunciante.',
  edit_advertiser_user: 'Editar Usuario Anunciante',
  advertiser_user_updated: 'Usuario Anunciante Actualizado',
  add_advertiser_user: 'Añadir Usuario Anunciante',
  create_advertiser_user: 'Crear Usuario Anunciante',
  custom_merchant_domain:
    'Dominio Personalizado del Gestor SSL (par a anunciantes)',
  login_as_advertiser: 'Iniciar Sesión como Anunciante',
  login_as_admin: 'Iniciar Sesión como Administrador',
  adv_registration_url: 'URL de Registro de Anunciante',
  free_trial_ending: 'La prueba gratuita termina {0}',
  email_config_activation: 'Activación',
  email_config_reset_password: 'Restablecimiento de contraseña',
  email_config_changed_email: 'Cambio de correo electrónico',
  email_config_welcome:
    'Bienvenido (El correo electrónico se enviará solo cuando exista el texto del cuerpo)',
  email_config_request_to_join_campaign_approved:
    'Solicitud para unirse a la campaña aprobada',
  email_config_request_to_join_campaign_declined:
    'Solicitud para unirse a la campaña rechazada',
  email_config_admin_status_approved: 'Estado de administrador aprobado',
  email_config_admin_status_changed: 'Estado de administrador cambiado',
  email_config_payment_request_approved: 'Solicitud de pago aprobada',
  email_config_payment_request_declined: 'Solicitud de pago rechazada',
  invoice_details: 'Detalles de la factura',
  profile_pic: 'Foto de Perfil',
  custom_statuses: 'Estados personalizados',
  add_custom_status: 'Agregar estado personalizado',
  edit_custom_status: 'Editar estado personalizado',
  ip_cooldown: 'Período de Enfriamiento de IP',
  ip_cooldown_helper:
    'El período de espera entre cada conversión para una dirección IP dada.',
  instant: 'Instantáneo',
  one_hour: 'Una hora',
  one_day: 'Un día',
  one_week: 'Una semana',
  one_month: 'Un mes',
  one_year: 'Un año',
  free_mode: 'Modo libre',
  use_a_semicolon:
    'Use un punto y coma para añadir múltiples pares de divisas, por ejemplo: AUDCAD;EURUSD',
  new_symbols_will_be_added:
    'Los nuevos símbolos se añadirán automáticamente con un pip de 4 (se puede cambiar más tarde)',
  group_name: 'Nombre del grupo',
  send_as_newsletter: 'Enviar como boletín',
  send_as_newsletter_desc: `Este boletín se enviará como boletín a todos los afiliados activos (si se selecciona una campaña, solo los afiliados de la campaña recibirán un correo electrónico). Esta acción no se puede deshacer y solo funcionará si la empresa tiene configurado SMTP.
`,
  primary_color: 'Color primario',
  secondary_color: 'Color secundario',
  dark_mode: 'Modo oscuro',
  light_mode: 'Modo claro',
  display_deposits_and_withdrawals: 'Mostrar depósitos y retiros por defecto',
  display_accessibility_menu: 'Mostrar menú de accesibilidad',
  lifetime_affiliates: 'Afiliados de por vida',
  customer_id: 'Identificación del cliente',
  leads: 'Clientes potenciales',
  lead_created: 'Cliente potencial creado',
  lead_updated: 'Cliente potencial actualizado',
  add_lead: 'Agregar cliente potencial',
  create_lead: 'Crear cliente potencial',
  edit_lead: 'Editar cliente potencial',
  NEW: 'Nuevo',
  CONTACTED: 'Contactado',
  QUALIFIED: 'Calificado',
  CONVERTED: 'Convertido',
  CLOSED: 'Cerrado',
  mlm_type: 'Tipo de MLM',
  brand_banner: 'Banner de Marca',
  add_to_dashboard: 'Añadir al tablero',
  remove_from_dashboard: 'Eliminar del tablero',
  edit_new_creative: 'Editar nueva creatividad',
  remove_affiliate_withdrawal_request:
    'Eliminar solicitud de retiro de afiliado',
  remove_withdrawal_info_helper:
    'Cuando activas esta opción, el botón de solicitud de retiro asociado se eliminará del portal de afiliados',
  campaign_name_clone: 'Confirmar clonación de {campaignTitle}',
  clone: 'Clonar',
  campaign_clone_message:
    'Está a punto de clonar esta campaña. Tenga en cuenta lo siguiente: \n \u200B \n1. Los afiliados no serán clonados. \n2. Solo se copiarán las configuraciones relacionadas con el seguimiento, la configuración general y los pagos.\n3. La campaña clonada estará pausada. \n \u200B \n ¿Está seguro de que desea proceder con la clonación?',
  automatically: 'Automáticamente',
  by_affiliate_request: 'Por Solicitud de Afiliado',
  choose_how_to_handle_payouts:
    'Elige cómo deseas manejar los pagos. Automáticamente o por Solicitud de Afiliado.',
  period_type: 'Tipo de Período',
  day_of_month: 'Día del Mes',
  day_of_week: 'Día de la Semana',
  allow_custom_amount_helper:
    'Activa esta opción para permitir que los afiliados ingresen una cantidad de pago manualmente.',
  allow_attachment_helper:
    'Activa esta opción para permitir el adjunto de solicitud de pago.',
  allow_enter_amount: 'Permitir Ingresar Cantidad',
  allow_an_attachment: 'Permitir Adjunto',
  allow_api_keys_by_default: 'Permitir claves API por defecto',
  allow_api_keys_helper: 'Este cambio no afectará a los afiliados existentes',
  form_editor: 'Editor de Formularios',
  form_preview: 'Vista Previa del Formulario',
  lifetime_lifecycle: 'Ciclo de vida de por vida',
  tiers_migration: 'Niveles',
  bonus: 'Bono',
  deposits_count: 'Depósitos',
  registrations_count: 'Registros',
  deposits_amount: 'Monto de depósitos',
  threshold_reward_type: 'Tipo de recompensa por umbral',
  campaign_payout_automation_bonuses:
    'Bonos de automatización de pagos de campaña',
  recurring_bonus: 'Bono recurrente',
  recurring_bonus_helper_text:
    'Cuando está activado, el bono se calcula mensualmente. Cuando está desactivado, es un bono único por afiliado.',
  lifetime_lifecycle_helper:
    'Agrega fecha de expiración a los afiliados de por vida. Se aplica solo a nuevos afiliados de por vida creados después del cambio',
  has_expiration_date: 'Tiene Fecha de Expiración',
  campaign_payout_automation_title: 'Ajustes automáticos de pagos a afiliados',
  campaign_payout_automation_subtitle:
    'Hemos introducido una función mejorada para optimizar los pagos a afiliados en función de su rendimiento. Así es como funciona:',
  campaign_payout_automation_desc_evaluation:
    '<strong>Evaluación de rendimiento:</strong> Al comienzo de cada mes, el sistema calcula el rendimiento de los afiliados utilizando métricas clave como cantidad de conversiones, volumen y cantidad de conversiones.',
  campaign_payout_automation_desc_performance_tiers:
    '<strong>Niveles de rendimiento con umbrales:</strong> Los afiliados se asignan a niveles específicos según su rendimiento en relación con los umbrales predefinidos.',
  campaign_payout_automation_desc_rewarding_performance:
    '<strong>Ajustes de pagos:</strong> Los afiliados que superan sus objetivos del mes anterior son promovidos automáticamente a un nivel de pago superior.',
  campaign_payout_automation_desc_personalized_payouts:
    '<strong>Bonificaciones para afiliados:</strong> Los afiliados ganan bonificaciones al cumplir con umbrales específicos. Si hay varias bonificaciones disponibles dentro de una única automatización, el sistema otorga la bonificación vinculada al umbral más alto alcanzado. El monto de la bonificación se define en la configuración de pagos de bonificación.',
  estimated_original_price: 'Precio Original Estimado',
  conversion_rate: 'Tasa de conversión',
  igaming_reports: 'Informes de iGaming',
  clicks_to_registrations: 'Clics a Registros',
  registrations_to_deposits: 'Registros a Depósitos',
  clicks_to_deposits: 'Clics a Depósitos',
  fraud_score: 'Posibilidad de fraude',
  import_conversions: 'Importar conversiones',
  update_conversions: 'Actualizar conversiones',
  if_commission_not_specified:
    'Si no se especifica, las comisiones se ajustarán automáticamente según el monto.',
  should_affiliates_change:
    '¿Debe aplicarse el cambio de afiliado a todas las cuentas bajo el usuario que actualmente tienen el mismo afiliado anterior?',
  min_amount: 'Monto Mínimo',
  view_more: 'Ver más'
};

import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import Iconify from 'src/components/iconify';
import ColoredLabel from '../components/ColoredLabel';
import { PaymentTypeEnum } from './enums/PaymentTypeEnum';
import { PayoutTypeEnum } from './enums/PayoutTypeEnum';
import { UserPermissionsEnum } from './enums/UserPermissionsEnum';
import { SiteTypeEnum } from '../components/guards/BrokerGuard';

export const newPayoutBuilder = payoutType => ({
  payoutsIdentity: 0,
  payoutsSettingsTitle: '',
  payoutsSettingsMerchantCommission: 0,
  payoutsSettingsAffiliateCommission: 0,
  payoutsSettingsPaymentType: PaymentTypeEnum.CPS,
  payoutsSettingsPayoutType:
    payoutType === PayoutTypeEnum.Personal
      ? PayoutTypeEnum.Personal
      : PayoutTypeEnum.General,
  payoutsSettingsGoalValue: '',
  payoutsSettingsCouponCode: '',
  payoutsSettingsAffiliates: []
});

export const existingPayoutBuilder = payout => ({
  payoutsIdentity: payout?.identity ? payout.identity : 0,
  payoutsSettingsTitle: payout?.title ? payout?.title : '',
  payoutsSettingsMerchantCommission: payout?.merchantCommission
    ? payout.merchantCommission
    : 0,
  payoutsSettingsAffiliateCommission: payout?.affiliateCommission
    ? payout.affiliateCommission
    : 0,
  payoutsSettingsPaymentType: payout.paymentType
    ? PaymentTypeEnum[payout.paymentType]
    : PaymentTypeEnum.CPA,
  payoutsSettingsPayoutType: payout.payoutType
    ? PayoutTypeEnum[payout.payoutType]
    : PayoutTypeEnum.General,
  payoutsSettingsGoalValue: payout?.goalValue ? payout.goalValue : '',
  payoutsSettingsCouponCode: payout?.couponCode ? payout.couponCode : '',
  payoutsSettingsAffiliates: payout?.affiliates
    ? payout.affiliates.map(affiliate => ({
        value: affiliate.userSerialOid,
        label: affiliate.affiliateCompanyName
      }))
    : [],
  symbols: payout?.symbols
    ? payout.symbols.map(symbol => ({
        value: symbol,
        label: symbol.name
      }))
    : []
});

export const phoneRegExp = /^[+\d\- ]{8,20}$/;

export const SettingsTabsEnum = {
  GeneralSubheader: {
    value: 'GeneralSubheader',
    label: 'general',
    subheader: true
  },
  PersonalSettings: {
    value: 'personal-settings',
    label: 'personal_settings',
    icon: 'ri:user-settings-line'
  },
  Security: {
    value: 'security',
    label: 'security',
    icon: 'material-symbols:security'
  },
  CompanySettings: {
    value: 'company-settings',
    label: 'company_settings',
    icon: 'mdi:shop-settings-outline',
    scope: UserPermissionsEnum.SETTINGS.value
  },
  CompanyMlmSettings: {
    value: 'company-mlm-settings',
    label: 'mlm_settings',
    icon: 'gis:globe-users',
    scope: UserPermissionsEnum.SETTINGS.value
  },
  TrafficSources: {
    value: 'traffic-sources',
    label: 'traffic_sources',
    scope: UserPermissionsEnum.TRAFFIC_SOURCES.value,
    icon: 'material-symbols:traffic-outline'
  },
  AffiliateSubheader: {
    value: 'AffiliateSubheader',
    label: 'users',
    scope: UserPermissionsEnum.SETTINGS.value,
    subheader: true
  },

  MerchantManagement: {
    value: 'user-management',
    label: 'user_management',
    scope: UserPermissionsEnum.SETTINGS.value,
    icon: 'la:user-cog'
  },
  AdvertiserUsersManagement: {
    value: 'advertiser-user-management',
    label: 'advertiser_user_management',
    scope: UserPermissionsEnum.SETTINGS.value,
    icon: 'mdi:partnership-outline'
  },
  EmailSubheader: {
    value: 'EmailSubheader',
    label: 'emails',
    scope: UserPermissionsEnum.SETTINGS.value,

    subheader: true
  },
  SMTP: {
    value: 'smtp',
    label: 'smtp',
    scope: UserPermissionsEnum.SETTINGS.value,
    icon: 'entypo:email'
  },
  Email: {
    value: 'email',
    label: 'affiliate_emails',
    scope: UserPermissionsEnum.SETTINGS.value,
    icon: 'ic:outline-email'
  },
  CustomizeSubheader: {
    value: 'CustomizeSubheader',
    label: 'customize',
    scope: UserPermissionsEnum.SETTINGS.value,
    subheader: true
  },
  Branding: {
    value: 'branding',
    label: 'branding',
    scope: UserPermissionsEnum.SETTINGS.value,
    icon: 'fluent:design-ideas-20-regular'
  },
  Translations: {
    value: 'translations',
    label: 'platform_translation_management',
    scope: UserPermissionsEnum.SETTINGS.value,
    icon: 'hugeicons:translation'
  },
  Documents: {
    value: 'documents',
    label: 'documents',
    scope: UserPermissionsEnum.SETTINGS.value,
    icon: 'solar:documents-minimalistic-line-duotone'
  },
  CustomFields: {
    value: 'custom-fields',
    label: 'custom_fields',
    scope: UserPermissionsEnum.SETTINGS.value,
    icon: 'solar:text-field-focus-broken'
  },
  CustomDocs: {
    value: 'custom-docs',
    label: 'custom_docs',
    scope: UserPermissionsEnum.SETTINGS.value,
    icon: 'solar:documents-minimalistic-linear'
  },
  PaymentMethods: {
    value: 'payment-methods',
    label: 'payment_methods',
    scope: UserPermissionsEnum.SETTINGS.value,
    icon: 'fluent:payment-32-regular'
  },
  CustomStatuses: {
    value: 'custom-statuses',
    label: 'custom_statuses',
    scope: UserPermissionsEnum.SETTINGS.value,
    icon: 'fluent:status-20-regular'
  },
  CampaignCategories: {
    value: 'campaign-categories',
    label: 'campaign_categories',
    scope: UserPermissionsEnum.SETTINGS.value,
    icon: 'ic:twotone-category'
  },
  UserCategories: {
    value: 'user-categories',
    label: 'affiliate_group',
    scope: UserPermissionsEnum.SETTINGS.value,
    icon: 'ic:twotone-category'
  },
  integrationsSubheader: {
    label: 'integrations',
    scope: UserPermissionsEnum.SETTINGS.value,
    subheader: true
  },
  Webhooks: {
    value: 'webhooks',
    label: 'webhooks',
    scope: UserPermissionsEnum.SETTINGS.value,
    icon: 'ph:webhooks-logo'
  },
  ApiKeys: {
    value: 'ApiKeys',
    label: 'Api Keys',
    scope: UserPermissionsEnum.API_KEYS.value,
    icon: 'basil:key-outline'
  }
};

export const BillingTabsEnum = {
  Plan: {
    value: 'plan',
    label: 'plan'
  },
  Payments: {
    value: 'payments',
    label: 'payments'
  }
};

export const DATE_PICKER_FORMAT = 'yyyy-MM-dd';

export const DATE_DISPLAY_FORMAT = 'MM-dd-yyyy HH:mm:ss';

export const DATE_AMERICAN_FORMAT = "yyyy-MM-dd'T'HH:mm:ss";

export const DATE_DEFUALT_FORMAT = 'DD-MM-YYYY HH:mm';

export const DATE_FORMATS = [
  'DD-MM-YYYY',
  DATE_DEFUALT_FORMAT,
  'MM/DD/YYYY',
  'MM/DD/YYYY HH:mm',
  'MM/DD/YYYY HH:mm:ss',
  'DD/MM/YYYY',
  'DD/MM/YYYY HH:mm',
  'DD/MM/YYYY HH:mm:ss',
  'YYYY/MM/DD',
  'YYYY/MM/DD HH:mm',
  'YYYY/MM/DD HH:mm:ss',
  'MMMM DD, YYYY',
  'MMMM DD, YYYY HH:mm',
  'MMMM DD, YYYY HH:mm:ss',
  'DD MMMM YYYY',
  'DD MMMM YYYY HH:mm',
  'DD MMMM YYYY HH:mm:ss'
];

export const noImageRelativePath = '/no-image.png';

export const green = (
  <ColoredLabel color="green">
    <FormattedMessage id="active" />
  </ColoredLabel>
);

export const ApprovedLabel = ({ isApproved }) => (
  <ColoredLabel color={isApproved ? 'green' : 'red'}>
    <FormattedMessage id={isApproved ? 'approved' : 'disapproved'} />
  </ColoredLabel>
);

export const red = (
  <ColoredLabel color="red">
    <FormattedMessage id="not_active" />
  </ColoredLabel>
);

export const ActiveLabel = ({ isActive }) => (
  <ColoredLabel color={isActive ? 'green' : 'red'}>
    <FormattedMessage id={`${!isActive ? 'not_' : ''}active`} />
  </ColoredLabel>
);

// eslint-disable-next-line react/prop-types
export function AllowedTrafficSource({ justifyContent }) {
  return (
    <span
      style={{
        color: 'rgb(73, 142, 68)',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent
      }}
    >
      <Iconify icon="fe:check-circle" />
      <Typography style={{ marginLeft: '5px' }}>
        <FormattedMessage id="allowed" />
      </Typography>
    </span>
  );
}

// eslint-disable-next-line react/prop-types
export function DisAllowedTrafficSource({ justifyContent }) {
  return (
    <span
      style={{
        color: 'rgb(185, 59, 47)',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent
      }}
    >
      <Iconify icon="mdi:minus-circle" />
      <Typography style={{ marginLeft: '5px', paddingBottom: '2px' }}>
        <FormattedMessage id="disallowed" />
      </Typography>
    </span>
  );
}

export const cnameExplanation = affiliateUrl => (
  <>
    <p>
      1. Sign in to your hosting account, and go to the DNS management page.
    </p>
    <p>
      {
        "  * CNAME records may be located in such sections as 'DNS Management' or 'Name Server Management'."
      }
    </p>
    <br />
    <p>2. You may have to enable advanced settings to create a CNAME record.</p>
    <p>
      {
        "  * Please contact your domain host directly if you didn't locate the CNAME management options."
      }
    </p>
    <br />
    <p>
      3. Create a CNAME record which directs to <b>{affiliateUrl}</b>.
    </p>
    <br />
    {/* eslint-disable-next-line react/no-unescaped-entities */}
    <p>4. Once completed, add your CNAME record and click the save.</p>
  </>
);

export const noData = '-';

export const MISSING_2FA_TOKEN = 'MISSING_2FA_TOKEN';

export const INVALID_2FA_TOKEN = 'INVALID_2FA_TOKEN';

export const NO_PERMISSION = 'NO_PERMISSION';

export const AffiliateTabsEnum = {
  Stats: {
    value: 'stats',
    label: 'stats',
    icon: 'gridicons:stats'
  },
  AffiliateDetails: {
    value: 'affiliate-details',
    label: 'affiliate_details',
    icon: 'mdi:account-details'
  },
  Commissions: {
    value: 'commissions',
    label: 'commissions',
    scope: UserPermissionsEnum.CONVERSIONS.value,
    icon: 'material-symbols:humidity-percentage-rounded'
  },
  Payments: {
    value: 'payments',
    label: 'payments',
    scope: UserPermissionsEnum.FINANCIAL_USERS.value,
    icon: 'fluent:payment-32-regular'
  },
  PayoutGroups: {
    value: 'payout-groups',
    label: 'payout_groups',
    scope: UserPermissionsEnum.AFFILIATE_PAYOUT_W.value,
    icon: 'material-symbols:groups'
  },
  Documents: {
    value: 'documents',
    label: 'documents',
    icon: 'solar:documents-linear'
  },
  Notes: {
    value: 'notes',
    label: 'notes',
    icon: 'solar:notes-linear'
  },
  Permissions: {
    value: 'permissions',
    label: 'permissions',
    icon: 'icon-park-outline:permissions'
  },
  FinancialUsers: {
    value: 'financial-users',
    label: 'financial_users',
    scope: UserPermissionsEnum.FINANCIAL_USERS.value,
    siteType: [SiteTypeEnum.BROKER, SiteTypeEnum.CASINO],
    icon: 'mdi:users'
  },
  FinancialAccounts: {
    value: 'financial-accounts',
    label: 'financial_accounts',
    scope: UserPermissionsEnum.FINANCIAL_USERS.value,
    siteType: [SiteTypeEnum.BROKER],
    icon: 'ph:eye'
  },
  SocialProfiles: {
    value: 'social-profiles',
    label: 'social_profiles',
    socialMode: true,
    icon: 'tabler:social'
  },
  Mlm: {
    value: 'mlm',
    label: 'mlm',
    icon: 'gis:globe-users'
  },
  CustomMlm: {
    value: 'custom-mlm',
    label: 'custom_mlm',
    icon: 'ic:sharp-dashboard-customize'
  },
  Audit: {
    value: 'audit',
    label: 'audit',
    icon: 'carbon:user-activity'
  }
};

/* eslint-disable react/no-array-index-key */
import { Grid, Typography } from '@mui/material';
import { Fragment, lazy, Suspense } from 'react';
import { FormattedMessage } from 'react-intl';
import { Redirect, Route, Switch } from 'react-router-dom';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/guards/AuthGuard';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import LoginView from 'src/views/auth/LoginView';
import Page from './components/Page';
import DomainLoginGuard from './components/guards/DomainLoginGuard';
import ScopeGuard from './components/guards/ScopeGuard';
import { UserPermissionsEnum } from './utils/enums/UserPermissionsEnum';
import AdminLoginView from './views/AdminLoginView';
import CampaignGeneralSettingsForm from './views/management/campaigns/CampaignEditOCreateView/CapmaignWizard/CampaignGeneralSettingsForm';

const PayoutsPage = lazy(() =>
  import('src/views/management/payouts/PayoutsListView/PayoutsListView')
);
// Todo: move all lazy improts to diff file
const routesConfig = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="app/reports/dashboard" />
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/pages/Error404View'))
  },
  {
    exact: true,
    path: '/admin-login/:accessToken',
    component: AdminLoginView
  },
  {
    path: '/reset',
    exact: true,
    component: lazy(() => import('src/utils/pageRouteUtils/ajaxRequestRouter'))
  },
  {
    path: '/activate',
    exact: true,
    component: lazy(() => import('src/utils/pageRouteUtils/ajaxRequestRouter'))
  },
  {
    path: '/activationDone',
    guard: AuthGuard,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/auth/Activation/ActivationDone'))
  },
  {
    path: '/activationFailure',
    component: lazy(() => import('src/views/auth/Activation/ActivationFailure'))
  },
  {
    path: '/changePassword',
    exact: true,
    component: lazy(() => import('src/views/auth/ChangePasswordView'))
  },
  {
    exact: true,
    guard: DomainLoginGuard,
    path: '/login',
    component: LoginView
  },
  {
    exact: true,
    guard: DomainLoginGuard,
    path: '/adv/login',
    component: props => LoginView({ ...props, isAdvertiser: true })
  },
  {
    exact: true,
    guard: DomainLoginGuard,
    path: '/register',
    component: lazy(() =>
      import('src/views/auth/RegisterView/affiliateManager')
    )
  },
  {
    exact: true,
    guard: DomainLoginGuard,
    path: '/adv/registration',
    component: lazy(() => import('src/views/auth/RegisterAdvertiserView/index'))
  },

  {
    path: '/changePassword/:token',
    component: lazy(() => import('src/views/auth/ChangePasswordView'))
  },
  {
    exact: true,
    path: '/forgotPassword',
    component: lazy(() => import('src/views/auth/ForgotPassword'))
  },
  {
    exact: true,
    path: '/app/activation/:accessToken',
    component: lazy(() => import('src/views/LoginWithTokenGate/index'))
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/management/advertisers',
        component: lazy(() =>
          import('src/views/management/advertisers/AdvertisersListView')
        )
      },
      {
        exact: true,
        path: '/app/management/advertisers/registrations',
        component: lazy(() =>
          import('src/views/management/advertisers/AdvertiserRegistrations')
        )
      },
      {
        path: '/app/groupings',
        routes: [
          {
            exact: true,
            path: '/app/groupings/symbols',
            component: lazy(() =>
              import('src/views/groupings/symbolGrouping/SymbolGrouping')
            )
          },
          {
            exact: true,
            path: '/app/groupings/countries',
            component: lazy(() =>
              import(
                'src/views/groupings/countriesGrouping/CountriesGrouping.js'
              )
            )
          }
        ]
      },
      {
        exact: true,
        path: '/app/management/news',
        component: lazy(() => import('src/views/pages/NewsPage'))
      },
      {
        exact: true,
        path: '/app/management/advertisers/add',
        component: lazy(() => import('src/views/management/AdvertiserEditView'))
      },
      {
        exact: true,
        path: '/app/management/campaigns/new',
        component: () => (
          <Page>
            <Grid pt={3} container spacing={3}>
              <Grid xs={12}>
                <Typography mb={3} variant="h3" color="textPrimary">
                  <FormattedMessage id="create_campaign" />
                </Typography>
                <CampaignGeneralSettingsForm />
              </Grid>
            </Grid>
          </Page>
        )
      },
      {
        exact: true,
        path: '/app/management/campaigns/:campaignSerialOid',
        component: lazy(() =>
          import(
            'src/views/management/campaigns/CampaignEditOCreateView/CapmaignWizard'
          )
        )
      },
      {
        exact: true,
        path: '/app/management/campaigns',
        component: lazy(() =>
          import('src/views/management/campaigns/CampaignsListView/Campaigns')
        )
      },

      {
        exact: true,
        path: '/app/welcome',
        component: lazy(() => import('src/views/pages/WelcomePage'))
      },

      {
        exact: true,
        path: '/app/chat',
        component: lazy(() => import('src/components/SocketTest.js'))
      },
      {
        exact: true,
        path: '/app/management/campaign/:campaignSerialOid',
        component: lazy(() => import('src/views/management/campaignView'))
      },
      {
        exact: true,
        path: '/app/management/settings',
        component: lazy(() => import('src/views/management/Setting'))
      },
      {
        exact: true,
        path: '/app/management/integrations',
        component: lazy(() =>
          import('src/views/management/integrations/IntegrationsPage')
        )
      },
      {
        exact: true,
        path: '/app/management/settings/:tab',
        component: lazy(() => import('src/views/management/Setting'))
      },
      {
        exact: true,
        path: '/app/management/settings/traffic-sources/add',
        component: lazy(() =>
          import(
            'src/views/management/Setting/SettingEditOCreateView/tabs/TrafficSourceTab'
          )
        )
      },
      {
        exact: true,
        path:
          '/app/management/settings/traffic-sources/:companyTrafficSourceOid',
        component: lazy(() =>
          import(
            'src/views/management/Setting/SettingEditOCreateView/tabs/TrafficSourceTab'
          )
        )
      },
      {
        exact: true,
        path: '/app/management/settings/custom-docs/add',
        component: lazy(() =>
          import(
            'src/views/management/Setting/SettingEditOCreateView/tabs/CustomDocs'
          )
        )
      },
      {
        exact: true,
        path: '/app/management/settings/custom-fields/add',
        component: lazy(() =>
          import(
            'src/views/management/Setting/SettingEditOCreateView/tabs/CustomField'
          )
        )
      },
      {
        exact: true,
        path: '/app/management/settings/custom-fields/:customFieldOid',
        component: lazy(() =>
          import(
            'src/views/management/Setting/SettingEditOCreateView/tabs/CustomField'
          )
        )
      },
      {
        exact: true,
        path: '/app/management/settings/custom-docs/:customDocOid',
        component: lazy(() =>
          import(
            'src/views/management/Setting/SettingEditOCreateView/tabs/CustomDocs'
          )
        )
      },
      {
        exact: true,
        path: '/app/management/settings/payment-methods/add',
        component: lazy(() =>
          import(
            'src/views/management/Setting/SettingEditOCreateView/tabs/PaymentMethod'
          )
        )
      },
      {
        exact: true,
        path: '/app/management/settings/payment-methods/:paymentMethodOid',
        component: lazy(() =>
          import(
            'src/views/management/Setting/SettingEditOCreateView/tabs/PaymentMethod'
          )
        )
      },
      {
        exact: true,
        path: '/app/management/billing',
        component: lazy(() => import('src/views/management/Billing/index'))
      },
      {
        exact: true,
        path: '/app/management/billing/:tab',
        component: lazy(() => import('src/views/management/Billing/index'))
      },
      {
        exact: true,
        path: '/app/management/contact-us',
        component: lazy(() => import('src/views/management/ContactUs/index'))
      },
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/reports/dashboard" />
      },
      {
        exact: true,
        path: '/app/reports/dashboard',
        component: lazy(() =>
          import('src/views/management/DashboardAlternativeView')
        )
      },
      {
        exact: true,
        path: '/app/reports/dashboard/social',
        component: lazy(() => import('src/views/management/SocialDashboard'))
      },
      {
        exact: true,
        path: '/app/reports/dashboard/social/profiles',
        component: lazy(() =>
          import(
            'src/views/management/affiliates/AffiliateManagement/SocialProfileTable.js'
          )
        )
      },
      {
        exact: true,
        path: '/app/reports',
        component: () => <Redirect to="/app/reports/dashboard" />
      },
      {
        exact: true,
        path: '/app/management/advertisers/:advertiserSerialOid/edit',
        component: lazy(() => import('src/views/management/AdvertiserEditView'))
      },

      {
        exact: true,
        path: '/app/management/invoices/:invoiceId',
        component: lazy(() => import('src/views/management/InvoiceDetailsView'))
      },
      {
        exact: true,
        path: '/app/management/invoices/payout/:invoiceId',
        component: lazy(() =>
          import('src/views/management/AffiliatePayoutInvoiceDetailsView')
        )
      },
      {
        exact: true,
        path: '/app/management/statistics/campaigns',
        component: lazy(() =>
          import('src/views/management/StatisticsListView/campaigns')
        )
      },
      {
        exact: true,
        path: '/app/management/statistics/igaming-reports',
        component: lazy(() =>
          import(
            'src/views/management/StatisticsListView/iGaming/IGamingReportsPage'
          )
        )
      },
      {
        exact: true,
        path: '/app/management/statistics/financial',
        component: lazy(() =>
          import('src/views/management/StatisticsListView/financial')
        )
      },
      {
        exact: true,
        path: '/app/management/statistics/conversions',
        component: lazy(() =>
          import(
            'src/views/management/StatisticsListView/conversions/ConversionListView'
          )
        )
      },
      {
        exact: true,
        path: '/app/management/statistics/leads',
        component: lazy(() =>
          import(
            'src/views/management/StatisticsListView/conversions/LeadsPage'
          )
        )
      },
      {
        exact: true,
        path: '/app/management/finance/users',
        component: lazy(() =>
          import(
            'src/views/management/StatisticsListView/conversions/ForexRegistretionTable'
          )
        )
      },
      {
        exact: true,
        path: '/app/management/finance/users/add',
        component: lazy(() => import('src/views/forex/CreateBrokerReg'))
      },
      {
        exact: true,
        path: '/app/management/finance/users/:id',
        component: lazy(() => import('src/views/forex/CreateBrokerReg'))
      },
      {
        exact: true,
        path: '/app/management/finance/accounts',
        component: lazy(() => import('src/views/forex/ForexAccountsPage'))
      },
      {
        exact: true,
        path: '/app/management/finance/accounts/add',
        component: lazy(() => import('src/views/forex/CreateForexAccount'))
      },
      {
        exact: true,
        path: '/app/management/finance/accounts/:id',
        component: lazy(() => import('src/views/forex/CreateForexAccount'))
      },
      {
        exact: true,
        path: '/app/management/finance/transactions/add',
        component: lazy(() => import('src/views/forex/CreateForexTransaction'))
      },
      {
        exact: true,
        path: '/app/management/finance/transactions/:id',
        component: lazy(() => import('src/views/forex/CreateForexTransaction'))
      },
      {
        exact: true,
        path: '/app/management/finance/transactions',
        component: lazy(() => import('src/views/forex/ForexTransactionsTable'))
      },
      {
        exact: true,
        path: '/app/management/statistics/impressions',
        component: lazy(() =>
          import(
            'src/views/management/StatisticsListView/conversions/DailyImpressionsTable'
          )
        )
      },
      {
        exact: true,
        path: '/app/management/statistics/postback',
        component: lazy(() =>
          import(
            'src/views/management/StatisticsListView/conversions/PostbackLogsTable'
          )
        )
      },
      {
        exact: true,
        path: '/app/management/statistics/campaignLogs',
        component: lazy(() =>
          import(
            'src/views/management/StatisticsListView/conversions/CampaignLogsTable'
          )
        )
      },
      {
        exact: true,
        path: '/app/management/statistics/clicks',
        component: lazy(() =>
          import(
            'src/views/management/StatisticsListView/conversions/DailyClicksTable'
          )
        )
      },
      {
        exact: true,
        path: '/app/management/statistics/conversions/:conversionOid/edit',
        component: lazy(() =>
          import(
            'src/views/management/StatisticsListView/conversions/ConversionEditOCreate'
          )
        )
      },
      {
        exact: true,
        path: '/app/management/statistics/conversions/add',
        component: lazy(() =>
          import(
            'src/views/management/StatisticsListView/conversions/ConversionEditOCreate'
          )
        )
      },
      {
        exact: true,
        path: '/app/management/statistics/leads/add',
        component: lazy(() =>
          import(
            'src/views/management/StatisticsListView/conversions/LeadFormPage'
          )
        )
      },
      {
        exact: true,
        path: '/app/management/statistics/leads/:conversionOid',
        component: lazy(() =>
          import(
            'src/views/management/StatisticsListView/conversions/LeadFormPage'
          )
        )
      },
      {
        exact: true,
        path: '/app/management/payouts',
        component: () => (
          <ScopeGuard scope={UserPermissionsEnum.PAYOUTS.value}>
            <PayoutsPage />
          </ScopeGuard>
        )
      },
      {
        exact: true,
        path: '/app/management/payouts/:payoutOid/edit',
        component: lazy(() =>
          import('src/views/management/payouts/PayoutEditView')
        )
      },
      {
        exact: true,
        path: '/app/management/affiliates/campaign-requests',
        component: lazy(() =>
          import('src/views/management/affiliates/CampaignRequestsListView')
        )
      },
      {
        exact: true,
        path: '/app/management/affiliates/payouts-requests',
        component: lazy(() =>
          import(
            'src/views/management/affiliates/PayoutsListView/PayoutsListView'
          )
        )
      },
      {
        exact: true,
        path: '/app/management/affiliate-management',
        component: lazy(() =>
          import('src/views/management/affiliates/AffiliateManagement/ListView')
        )
      },
      {
        exact: true,
        path: '/app/management/lifetime-affiliates',
        component: lazy(() =>
          import(
            'src/views/management/affiliates/LifetimeAffiliates/LifetimeAffiliates'
          )
        )
      },
      {
        exact: true,
        path: '/app/management/affiliates/add',
        component: lazy(() =>
          import(
            'src/views/management/affiliates/AffiliateManagement/EditOCreate'
          )
        )
      },
      {
        exact: true,
        path: '/app/management/affiliates/balance',
        component: lazy(() =>
          import('src/views/management/affiliates/BalanceSheet/index')
        )
      },
      {
        exact: true,
        path: '/app/management/affiliates/:affiliateSerialOid/edit',
        component: lazy(() =>
          import(
            'src/views/management/affiliates/AffiliateManagement/EditOCreate'
          )
        )
      },
      {
        exact: true,
        path: '/app/management/affiliates/:affiliateSerialOid/manage',
        component: lazy(() =>
          import(
            'src/views/management/affiliates/AffiliateManagement/AffiliateManagePage'
          )
        )
      },
      {
        exact: true,
        path: '/app/management/affiliates/:affiliateSerialOid/manage/:tab',
        component: lazy(() =>
          import(
            'src/views/management/affiliates/AffiliateManagement/AffiliateManagePage'
          )
        )
      },
      {
        exact: true,
        path: '/app/management/merchant-users/:merchantSerialOid/edit',
        component: lazy(() =>
          import(
            'src/views/management/Setting/SettingEditOCreateView/tabs/EditOCreateUserManagement/EditOCreateUserManagement'
          )
        )
      },
      {
        exact: true,
        path: '/app/management/merchant-users/add',
        component: lazy(() =>
          import(
            'src/views/management/Setting/SettingEditOCreateView/tabs/EditOCreateUserManagement/EditOCreateUserManagement'
          )
        )
      },
      {
        exact: true,
        path: '/app/management/audit',
        component: lazy(() => import('src/views/management/AuditTable'))
      },
      {
        exact: true,
        path: '/app/management/webhooks',
        component: lazy(() =>
          import('src/views/management/Webhooks/WebhooksPage')
        )
      },
      {
        exact: true,
        path: '/app/management/logs/webhooks',
        component: lazy(() =>
          import('src/views/management/Webhooks/WebhookLogs/index')
        )
      },
      {
        exact: true,
        path: '/app/management',
        component: () => <Redirect to="/app/management/customers" />
      },
      {
        exact: true,
        path: '/app/projects',
        component: () => <Redirect to="/app/projects/browse" />
      },

      {
        exact: true,
        path: '/404',
        component: lazy(() => import('src/Error404View'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },

  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        path: '/',
        guard: AuthGuard,
        layout: DashboardLayout,
        routes: []
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

const renderRoutes = routes =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={props => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
